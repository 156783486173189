import * as React from 'react';
import {useState, useEffect, useContext, useCallback, createContext} from 'react';
import { ToastContext } from '..';
import {getUserScans, getScans, getScansPager} from '../api';
import { ScansTable } from './ScansTable';
import { SearchBar } from './SearchBar';
import { catchHandler, generateDataForTable } from './utils';
import { useUsersController, Users } from '../hooks/user';

interface TableModel {
  userName: string;
  utcDate: string;
  date: string;
  contact: string;
  scanId: string;
  userId: string;
}

let scansPager = getScansPager();

type UsersContextType = {
  users: Users;
  fetchUsersByID(ids: Array<string>): Promise<Users>;
  fetchNextPage(): Promise<any>;
  resetPager(): Promise<void>;
}

const defaultUsersContext = {
  users: {},
  fetchUsersByID: () => null,
  fetchNextPage: () => null,
  resetPager: () => null,
};

export const UsersContext = createContext<UsersContextType>(defaultUsersContext)

export const ScanTablePage: React.FC = () => {
  const [fromDatetime, setFromDatetime] = useState(null);
  const [toDatetime, setToDatetime] = useState(null);
  const [isSortedDesc, setIsSortedDesc] = useState(true);
  const [isFullyLoaded, setIsFullyLoaded] = useState(false);
  const [isPaging, setIsPaging] = useState(true);
  const [shouldGetNextPage, setShouldGetNextPage] = useState(true);
  const [renderScrollHeight, setRenderScrollHeight] = useState(0);
  const [tableData, setTableData] = useState<Array<TableModel>>([]);
  const [currentUserId, setCurrentUserId] = useState('');
  const { setToast } = useContext(ToastContext);

  const usersController = useUsersController()

  const clearSearchFilter = () => {
    scansPager = getScansPager(isSortedDesc, fromDatetime, toDatetime);
    setCurrentUserId('');
    setTableData([]);
    setIsFullyLoaded(false);
    setIsPaging(true);
    setShouldGetNextPage(true);
  };

  const generateNewTableData = useCallback( async (scansPage) => {
    if (scansPage.length < 1)
      return;

    let users;
    if (!currentUserId) {
      const scanUserIDs = scansPage.map(scan => scan.user);
      users = await usersController.fetchUsersByID(scanUserIDs);
    }

    let newTableData = generateDataForTable(scansPage, users || usersController.users);

    setTableData((_td) => [..._td, ...newTableData])
    setIsPaging(false);
  }, [currentUserId, usersController]);

  const pageScans = useCallback( async () => {
    try {
      setShouldGetNextPage(false);
      if (isFullyLoaded)
        return;
  
      let nextScansPage = currentUserId ? await scansPager(currentUserId) : await scansPager();
  
      if (!nextScansPage || nextScansPage.length === 0) {
        setIsFullyLoaded(true);
        if (currentUserId && tableData.length < 1)
          setToast({
            text: 'The user was found but has no available scans',
          });
        return;
      }
      else if (currentUserId) {
        nextScansPage.forEach(scan => scan.user = currentUserId);
        nextScansPage = nextScansPage.filter((scan) => scan.status === 'complete')
      }
      await generateNewTableData(nextScansPage);
    } catch (e) {
      setIsFullyLoaded(true);
      catchHandler(e, setToast)
    }
  }, [currentUserId, isSortedDesc, generateNewTableData]);

  const setNewCurrentUserId = async (userId) => {
    scansPager = getScansPager(isSortedDesc, fromDatetime, toDatetime);
    setCurrentUserId(userId);
    setTableData([]);
    setIsFullyLoaded(false);
    setIsPaging(true);
    setShouldGetNextPage(true);
  };

  const getNewerScans = async () => {
    try {
      if (tableData.length < 1)
      return;

      const fromDate = tableData[0].utcDate.replace(' ', 'T');
      let scans = currentUserId ?
        await getUserScans(currentUserId, false, false, 15, fromDate) :
        await getScans(false, false, 15, fromDate);

      if (!scans) {
        throw new Error('Network Error')
      }

      for (let i=0; i < tableData.length; i++) {
        if (tableData[i].scanId === scans[scans.length - 1].id) {
          scans.pop();
          break;
        }
      }
      await generateNewTableData(scans);
    } catch (e) {
      setIsFullyLoaded(true);
      catchHandler(e, setToast)
    }
  };

  const refreshHandler = async () => {
    if (isSortedDesc) {
      await getNewerScans();
    }
  };

  const sortHandler = (columnName, isDesc) => {
    if (columnName === 'date') {
      scansPager = getScansPager(isDesc, fromDatetime, toDatetime);
      setIsSortedDesc(isDesc);
      setTableData([]);
      setIsFullyLoaded(false);
      setIsPaging(true);
      setShouldGetNextPage(true);
    }
  };

  const pagingHandler = () => {
    if (!isPaging) {
      setIsPaging(true);
      setShouldGetNextPage(true);
    }
  };

  const datetimeHandler = (fromDT, toDT) => {
    let fixedToDT = null;
    if (toDT) {
      // Add 1 because the TO date is not inclusive
      fixedToDT = new Date(toDT);
      fixedToDT.setDate(fixedToDT.getDate() + 1)
    }
    const newFromDatetime = fromDT ? fromDT.toISOString().replace(".000", "") : null;
    const newToDatetime = fixedToDT ? fixedToDT.toISOString().replace(".000", "") : null;
    setFromDatetime(newFromDatetime);
    setToDatetime(newToDatetime);
    scansPager = getScansPager(isSortedDesc, newFromDatetime, newToDatetime);
    setTableData([]);
    setIsFullyLoaded(false);
    setIsPaging(true);
    setShouldGetNextPage(true);
  };

  useEffect(() => {
    if (shouldGetNextPage) {
      pageScans().catch((e) => catchHandler(e, setToast))
    }
  }, [isPaging, shouldGetNextPage, pageScans, setToast]);

  return (
    <UsersContext.Provider value={usersController}>
      <div
        style={{ marginBottom: '40px' }}
        className="d-flex justify-content-center mt-3"
      >
        <img
          alt="Amplify logo"
          src="img/logo.png"
          style={{ width: '100%', maxWidth: '350px', objectFit: 'contain' }}
        />
      </div>
      <SearchBar
        searchHandler={setNewCurrentUserId}
        clearSearchFilterHandler={clearSearchFilter}
        showClearButton={!!currentUserId}
        isSortedDesc={isSortedDesc}
        refreshHandler={refreshHandler}
        datetimeHandler={datetimeHandler}/>
      {tableData.length > 0 || isPaging ? (
        <>
          <ScansTable
            tableData={tableData}
            sortHandler={sortHandler}
            isSortedDesc={isSortedDesc}
            isFullyLoaded={isFullyLoaded}
            pagingHandler={pagingHandler}
            scrollHeight={renderScrollHeight}
            scrollPositionHandler={setRenderScrollHeight}
          />
        </>
      ) : (
        <h3>Scan list is empty</h3>
      )}
    </UsersContext.Provider>
  );
};
