import * as React from 'react';
import { useEffect, useState, useContext, useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import {
  getScan,
  getUser,
  getCoreMeasurements,
  getAppDateQueries,
  getDerivedData
} from '../api';
import { Table, Card } from 'react-bootstrap';
import Loading from '../components/Loading';
import { AppContext, ToastContext } from '..';
import { catchHandler } from './utils';

const useStyles = createUseStyles({
  tableWrap: {
    height: '400px',
    overflowY: 'auto',
    '& td': {
      '@media screen and (max-width: 768px)': {
        fontSize: '12px'
      }
    }
  }
});

interface ScanProps {
  scanId: string;
  userId: string;
  scanUrl: string;
}

export const Scan: React.FC<ScanProps> = ({ scanId, userId, scanUrl }) => {
  const styles = useStyles();
  const { id } = useContext(AppContext);
  const { setToast } = useContext(ToastContext);
  const [currentScan, setCurrentScan] = useState(null);
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [measurements, setMeasurements] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasCustomMeasures, setHasCustomMeasures] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const scan = await getScan(scanId);
        const customer = await getUser(userId);
        const addData = await getAppDateQueries(id);
        const customMeasures = addData.find(
          (elem) => elem.query === 'app-measures'
        );
        setHasCustomMeasures(customMeasures);
        if (customMeasures) {
          const appMeasures = await getDerivedData(scan.id, customMeasures.query);
          setMeasurements(appMeasures.output);
        } else {
          const coreMeasurements = await getCoreMeasurements(scanId);
          setMeasurements(coreMeasurements.output.measurements);
        }
        setCurrentCustomer(customer);
        setCurrentScan(scan);
        setLoading(false);
      } catch (error) {
        catchHandler(error, setToast)
        setLoading(false)
      }
    })();
  }, []);

  const measurementsArray = useMemo(() => {
    const keisArray = Object.keys(measurements);

    if (hasCustomMeasures) {
      return keisArray;
    }

    return keisArray.sort((a, b) => {
      const textA = a.toUpperCase();
      const textB = b.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
  }, [hasCustomMeasures, measurements]);

  if (loading) {
    return <Loading />;
  }

  if (!currentScan || !currentCustomer) {
    return null;
  }

  return (
    <>
      <Card.Title className="text-center pt-3">Review scan results</Card.Title>
      <Card.Body className="pb-5 pt-3">
        <div className="d-md-flex justify-content-between">
          <div>
            <h6>Date and time: {currentScan.date || '--'}</h6>
            <h6>Email: {currentCustomer.email || '--'}</h6>
            <h6>
              Phone:{' '}
              {currentCustomer.phone ? `+${currentCustomer.phone}` : '--'}
            </h6>
            <h6>
              Name: {currentCustomer.firstName} {currentCustomer.lastName}
            </h6>
          </div>
        </div>
      </Card.Body>
      <div className="d-grid gap-3 pt-4 pb-4">
        <div>
          <div className="text-center mb-4">
            <img
              src={scanUrl}
              style={{ height: '300px', objectFit: 'contain' }}
            />
          </div>
          <div className={styles.tableWrap}>
            <Table
              striped
              bordered
              hover
              style={{ maxWidth: '600px', margin: '0 auto' }}
            >
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                {measurementsArray.map((key) => (
                  <tr key={key}>
                    <td>{key}</td>
                    <td>{Number(measurements[key]).toFixed(1)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};
