import * as React from 'react';
import { Modal, Button, ModalProps } from 'react-bootstrap';
import QrCodeReader from './QrCodeReader';
import { SearchBarProps } from './SearchBar';
import { SearchByContactData } from './SearchByContactData';

export type SearchType = 'qrcode' | 'email' | 'phone';

interface SearchModalProps extends ModalProps, SearchBarProps {
  searchType: SearchType;
}

const searchModalContent = {
  qrcode: {
    heading: 'Search by qrcode',
    title: 'Enter qrcode'
  },
  email: {
    heading: 'Search by email',
    title: 'Enter email'
  },
  phone: {
    heading: 'Search by phone',
    title: 'Enter phone'
  }
};

export const SearchModal: React.FC<SearchModalProps> = ({
  searchType,
  searchHandler,
  ...props
}) => {
  const renderSearchContent = () => {
    switch (searchType) {
      case 'qrcode':
        return <QrCodeReader searchHandler={searchHandler} />;
      default:
        return (
          <SearchByContactData
            searchBy={searchType}
            searchHandler={searchHandler}
          />
        );
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {searchModalContent[searchType].heading}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{renderSearchContent()}</Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};
