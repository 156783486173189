export const columns = [
  {
    Header: 'Date',
    accessor: 'date'
  },
  {
    Header: 'User Name',
    accessor: 'userName',
    disableSortBy: true
  },
  {
    Header: 'Contact',
    accessor: 'contact',
    disableSortBy: true
  },
  {
    accessor: 'scanId'
  }
];

const padTo2Digits = (num) => {
  return num.toString().padStart(2, '0');
};

export const getDateWithCorrectFormat = (date) => {
  const dateWithCorrectFormat = (
    [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate())
    ].join('-') +
    ' ' +
    [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      padTo2Digits(date.getSeconds())
    ].join(':')
  );
  return dateWithCorrectFormat;
};

export const generateDataForTable = (scans: Array<any>, users: any) => {
  return scans.map((scan) => ({
    utcDate: scan.date,
    date: getDateWithCorrectFormat(new Date(scan.date)),
    userName: `${users?.[scan.user].firstName} ${users?.[scan.user].lastName}`,
    contact: users?.[scan.user].email || users?.[scan.user].phone,
    scanId: scan.id,
    userId: scan.user
  }));
};


export const catchHandler = (e, setToast) => {
  const error = JSON.stringify(e.message);

  if (e === 'Network Error' || error === '"Network Error"') {
    setToast({
      text: 'Check your internet and try again.',
      variation: 'error'
    });
  } else {
    console.log(e)
  }
}