import API from '@aws-amplify/api';

const THREE_D_ID_API = {
  BASE_URI: 'three-d-id-api'
};

export const getApps = async () => {
  try {
    const path = '/apps';
    return await API.get(THREE_D_ID_API.BASE_URI, path, null);
  } catch (e) {
    console.error(e);
  }
};

export const getUsers = async (
  hasPagination?: boolean,
  startAfter?: string | boolean,
  userIDs?: string[]
) => {
  const path = startAfter ? `/users?startAfter=${startAfter}` : '/users';
  const myInit = {
    headers: hasPagination ? {paginate: 'true'} : {},
    queryStringParameters: userIDs ? {id: userIDs} : {}
  };

  const res = await API.get(
    THREE_D_ID_API.BASE_URI,
    path,
    myInit
  );

  if (!res) {
    throw new Error('Network Error')
  }

  return res;
};

export function getUsersPager() {
  var continueFrom = null;
  return async function () {
    if (continueFrom == -1)
      return null;
    const page = await getUsers(true, continueFrom);

    if (!page) {
      throw new Error('Network Error')
    }
    continueFrom = page.continueFrom ? page.continueFrom : -1;
    return page.users;
  }
}

export const getScans = async (
  hasPagination?: boolean,
  startAfter?: string | boolean,
  count?: number,
  fromDate?: string,
  toDate?: string,
  sortOrder?: string,
) => {
  try {
    if (!count || count < 1)
      count = 15;
    let path = `/scans?count=${count}`;
    if (startAfter)
      path += `&startAfter=${startAfter}`;
    if (fromDate)
      path += `&from=${fromDate}`
    if (toDate)
      path += `&to=${toDate}`
    if (sortOrder)
      path += `&sortOrder=${sortOrder}`
    return await API.get(
      THREE_D_ID_API.BASE_URI,
      path,
      hasPagination
        ? {
            headers: {
              paginate: 'true'
            }
          }
        : {}
    );
  } catch (e) {
    console.error(e);
  }
};

export const getUserScans = async (
  userId,
  hasPagination?: boolean,
  startAfter?: string | boolean,
  count?: number,
  fromDate?: string,
  toDate?: string,
  sortOrder?: string,
) => {
  try {
    if (!count || count < 1)
      count = 15;
    let path = `/users/${userId}/scans?count=${count}`;
    if (startAfter)
      path += `&startAfter=${startAfter}`;
    if (fromDate)
      path += `&from=${fromDate}`
    if (toDate)
      path += `&to=${toDate}`
    if (sortOrder)
      path += `&sortOrder=${sortOrder}`
    return await API.get(
      THREE_D_ID_API.BASE_URI,
      path,
      hasPagination
        ? {
            headers: {
              paginate: 'true'
            }
          }
        : {}
    );
  } catch (ex) {
    console.error('error: ' + ex.toString());
    return -1;
  }
};

export function getScansPager(isDescendingPager=true, fromDatetime=null, toDatetime=null) {
  var continueFrom = null;
  const count = 15;
  const sortOrder = isDescendingPager ? null : 'asc';
  return async function (userId=null) {
    if (continueFrom == -1)
      return null;
    const page = userId
      ? await getUserScans(userId, true, continueFrom, count, fromDatetime, toDatetime, sortOrder)
      : await getScans(true, continueFrom, count, fromDatetime, toDatetime, sortOrder);

    if (!page) {
      throw new Error('Network Error')
    }

    continueFrom = page.continueFrom ? page.continueFrom : -1;
    return page.scans;
  }
}

export const getScanImage = async (
  scanId,
  query = 'body-mesh?format=image/png',
  prefix = ''
) => {
  const path = `/scans/${scanId}/data/${query}`;
  return await API.get(THREE_D_ID_API.BASE_URI, path, {
    headers: {
      'file-prefix': prefix
    }
  });
};

export const getAppQuery = async (appId) => {
  try {
    const path = `/apps/${appId}/dataQueries`;
    return await API.get(THREE_D_ID_API.BASE_URI, path, null);
  } catch (e) {
    console.log(e);
  }
};

export const getScan = async (scanId) => {
  try {
    const path = `/scans/${scanId}`;
    return await API.get(THREE_D_ID_API.BASE_URI, path, null);
  } catch (e) {
    console.log(e);
  }
};

export const getUser = async (userId) => {
  try {
    const path = `/users/${userId}`;
    return await API.get(THREE_D_ID_API.BASE_URI, path, null);
  } catch (ex) {
    console.error('error: ' + ex.toString());
    return ex.response;
  }
};

export const getCoreMeasurements = async (scanId) => {
  try {
    const path = `/scans/${scanId}/data/core-measures`;
    return await API.get(THREE_D_ID_API.BASE_URI, path, null);
  } catch (e) {
    console.log(e);
  }
};

export const getAppMeasurements = async (scanId) => {
  const path = `/scans/${scanId}/data/app-measures`;
  return await API.get(THREE_D_ID_API.BASE_URI, path, null);
};

export const getAppDateQueries = async (appId: string) => {
  try {
    const path = `/apps/${appId}/dataQueries`;
    return await API.get(THREE_D_ID_API.BASE_URI, path, null);
  } catch (e) {
    console.error(e);
  }
};

export const getDerivedData = async (scanId: string, query: string) => {
  try {
    const path = `/scans/${scanId}/data/${query}`;
    return await API.get(THREE_D_ID_API.BASE_URI, path, null);
  } catch (e) {
    console.error(e);
  }
};

export const deleteScan = async (scanId) => {
  try {
    const path = `/scans/${scanId}`;
    return await API.del(THREE_D_ID_API.BASE_URI, path, null);
  } catch (ex) {
    console.error('error: ' + ex.toString());
    return -1;
  }
};

export const getUserCountryCode = async () => {
  const res = await fetch(process.env.REACT_APP_IPAPI_URL);
  const countryCode = await res.text();

  return countryCode.toLowerCase();
};
