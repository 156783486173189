import * as React from 'react';
import { useState } from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { createUseStyles } from 'react-jss';
import { SearchModal, SearchType } from './SearchModal';
import { DateSelector } from "./DateSelector";

export interface SearchBarProps {
  searchHandler: (userId: string) => void;
}

const useStyles = createUseStyles({
  clearFilterButton: {
    border: 0,
    background: '#fff',
    outline: 'none',
    fontSize: '22px',
    textDecoration: 'underline',
    '&:hover': {
      opacity: 0.5
    }
  }
});

export const SearchBar: React.FC<
  SearchBarProps & {
    clearSearchFilterHandler: any;
    showClearButton: boolean;
    isSortedDesc: boolean;
    refreshHandler(): Promise<any>;
    datetimeHandler: any;
  }
> = ({
  clearSearchFilterHandler,
  showClearButton,
  refreshHandler: _refreshHandler,
  isSortedDesc,
  datetimeHandler,
  ...props
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [searchType, setSearchType] = useState<SearchType>('qrcode');
  const styles = useStyles();

  const clickHandler = (searchBy: SearchType) => {
    setIsModalOpen(true);
    setSearchType(searchBy);
  };

  const searchHandler = (userId) => {
    props.searchHandler(userId);
    setIsModalOpen(false);
  };

  const refreshHandler = async () => {
    setRefreshLoading(true)
    await _refreshHandler();
    setRefreshLoading(false)
  }

  return (
    <>
      <ButtonGroup style={{ marginBottom: 10 }} aria-label="Basic example">
        <Button style={{ flex: '33.3%' }} onClick={() => clickHandler('email')} variant="secondary">
          Find by Email
        </Button>
        <Button style={{ flex: '33.3%' }} onClick={() => clickHandler('phone')} variant="secondary">
          Find by phone
        </Button>
        <Button style={{ flex: '33.3%' }} disabled={!isSortedDesc} onClick={refreshHandler}>
          {isSortedDesc ? (refreshLoading ? (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />) : 'Check for Updates') : 'Nothing Older'}
        </Button>
      </ButtonGroup>
      <div className="d-flex justify-content-between align-items-center mb-3">
        {showClearButton && (
          <button
            onClick={clearSearchFilterHandler}
            className={styles.clearFilterButton}
          >
            Clear Search Filter
          </button>
        )}
      </div>
      <div className="d-flex justify-content-center mt-3">
        <DateSelector
          datetimeHandler={datetimeHandler}
        />
      </div>
      <SearchModal
        show={isModalOpen}
        onHide={() => setIsModalOpen(false)}
        searchType={searchType}
        searchHandler={searchHandler}
      />
    </>
  );
};
